<template>
  <div style="width:100%;height:100%;overflow-y: scroll;">
    <swiperItem style="width:100%;margin-left:0%;height:100%"></swiperItem>
    <BriefIntroduction @toIntro="getIntro" style="width:100%;height:66%;"></BriefIntroduction>
    <ProductIntroduction @toPath="getPath" style="width:100%;height:90%;margin-top:3.5%"></ProductIntroduction>
    <DynamicNews @toNews="getNews" style="width:100%;height:70%;margin-top:3%"></DynamicNews>
    <CooperativePartner style="width:100%;height:80%;margin-top:1%"></CooperativePartner>
    <Footers style="width:100%;height:27%;"></Footers>
  </div>
</template>

<script src="./js/HomePage.js">
</script>

<style src="./css/HomePage.css" scoped>

</style>